<style lang="scss" scoped>
/deep/ .comp-stock-condition {
    max-width: 420px;
}
</style>

<template>
    <div>
        <el-dialog title="切换事业部和仓库" custom-class="c-el-dialog comp-stock-condition" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
            <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="80px" size="small">
                <el-row :gutter="10">
                    <el-col :xs="24">
                        <el-form-item label="事业部" prop="companyId">
                            <el-select class="c-pw100" placeholder="请选择所属事业部" size="medium" filterable
                                clearable v-model="dialogData.companyId" @change="changeCompany()">
                                <el-option v-for="ws in allCompany" :key="ws.id" :label="ws.name" :value="ws.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="仓库" prop="warehouseId">
                            <el-select class="c-pw100" placeholder="请选择仓库" size="medium" filterable
                                clearable v-model="dialogData.warehouseId">
                                <el-option v-for="ws in allWarehouse" :key="ws.id" :label="ws.name" :value="ws.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-alert type="warning" :closable="false" title="选择并确定后会保存当前数据，如需修改请点击切换!"></el-alert>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button size="small" @click="_close()">关闭</el-button>
                <el-button size="small" type="primary" @click="_save()">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import * as funInfomation from "@/api/information"
import { getStockCompany, setStockCompany,
    getStockWarehourse, setStockWarehourse } from "@/utils/storages"
export default {
    name: "compStockCondition",
    data() {
        return {
            showDialog: false,
            dialogData: {
                companyId: "",
                warehouseId: ""
            },
            allCompany: [],
            allWarehouse: [],
            formRefName: "refStockCondition",
            formRules: {
                companyId: [{ required: true, message: '请选择所属事业部', trigger: ['blur', 'change']}],
                warehouseId: [{ required: true, message: '请选择仓库', trigger: ['blur', 'change']}]
            },

        }
    },
    methods: {
        async open() {
            await this.getAllCompany()
            await this.getAllWarehouse()
            this.showDialog = true
        },
        async getAllCompany(){
            window.$common.fullLoading()
            await funInfomation.searchCompany().then(res => {
                window.$common.closeFullLoading()
                this.allCompany = res.items
                // 能找到默认的事业部id则设置，否则清空
                let companyItem = getStockCompany()
                let curCompany = this.allCompany.find(x => {
                    return x.id === (companyItem && companyItem.id)
                })
                this.dialogData.companyId = curCompany && curCompany.id || ""
            })
        },
        async getAllWarehouse() {
            window.$common.fullLoading()
            await funInfomation.searchWarehouse({ companyId: this.dialogData.companyId }).then(res => {
                window.$common.closeFullLoading()
                this.allWarehouse = res.items
                // 能找到默认的仓库id则设置，否则清空
                let  warehourseItem = getStockWarehourse()
                let curWarehouse = this.allWarehouse.find(x => {
                    return x.id === (warehourseItem && warehourseItem.id)
                })
                this.dialogData.warehouseId = curWarehouse && curWarehouse.id || ""
            })
        },
        changeCompany() {
            this.dialogData.warehouseId = ""
            this.getAllWarehouse()
        },
        _save() {
            this.$refs[this.formRefName].validate(valid => {
                if (valid) {
                    let company = this.allCompany.find(x => {
                        return x.id === this.dialogData.companyId
                    })
                    let warehouse = this.allWarehouse.find(x => {
                        return x.id === this.dialogData.warehouseId
                    })
                    setStockCompany({ id: company.id, name: company.name })
                    setStockWarehourse({ id: warehouse.id, name: warehouse.name })
                    this.refreshData()
                    this._close()
                } else {
                    return false
                }
            })
        },
        _close() {
            this.showDialog = false
            this.$nextTick(() => {
                if (this.$refs[this.formRefName]) {
                    this.$refs[this.formRefName].resetFields()
                }
            })
        },
        refreshData(data) {
            this.$emit("refreshData", data)
        }
    }
}
</script>