export default {
    data () {
        return {}
    },
    mounted() {
        /*
            ["System", "Products", "Purchase", "Sales", "Stocks", "Materials", 
            "System.Config", "System.RoleManage", "System.AdminManage", "System.LoginLogs", "System.HandleLogs", 
            "Products.CategoryManage", "Products.BrandManage", "Products.ProductView", "Products.ProductCostView", 
            "Products.ProductCreate", "Products.ProductUpdate", "Products.ProductPriceUpdate", "Products.ProductDelete", 
            "Purchase.View", "Purchase.OrderCreate", "Purchase.OrderUpdate", "Purchase.OrderDelete", "Purchase.OrderStockPutIn", 
            "Sales.View", "Sales.OrderCreate", "Sales.OrderUpdate", 
            "Sales.OrderAudit", "Sales.OrderReceiveMoney", "Sales.OrderDelete", "Sales.OrderStockTakeOut", 
            "Stocks.Inventory", "Stocks.BillManage", "Stocks.SerialNumberManage", "Stocks.PutIn", 
            "Stocks.TakeOut", "Stocks.PurchaseReturn", "Stocks.SaleReturn", "Stocks.SaleExchange", "Stocks.ChangeLogs", 
            "Stocks.SerialNumberLogs", "System.WarehouseManage"]
        */
        // console.error(this.$store.getters.roles)
    },
    computed: {
        /* ----------身份角色，用户管理---------- */
        isAbpIdentity() {
            // 是否有身份大类权限
            return this.hasSpecialRole("AbpIdentity")
        },
        isRolesManage() {
            // 是否可以角色管理
            return this.hasSpecialRole("AbpIdentity.Roles")
        },
        isRolesCreate() {
            return this.hasSpecialRole("AbpIdentity.Roles.Create")
        },
        isRolesUpdate() {
            return this.hasSpecialRole("AbpIdentity.Roles.Update")
        },
        isRolesDelete() {
            return this.hasSpecialRole("AbpIdentity.Roles.Delete")
        },
        isRolesPermissions() {
            return this.hasSpecialRole("AbpIdentity.Roles.ManagePermissions")
        },
        isUsersManage() {
            // 是否可以用户管理
            return this.hasSpecialRole("AbpIdentity.Users")
        },
        isUsersCreate() {
            return this.hasSpecialRole("AbpIdentity.Users.Create")
        },
        isUsersUpdate() {
            return this.hasSpecialRole("AbpIdentity.Users.Update")
        },
        isUsersDelete() {
            return this.hasSpecialRole("AbpIdentity.Users.Delete")
        },
        isUsersPermissions() {
            return this.hasSpecialRole("AbpIdentity.Users.ManagePermissions")
        },
        /* ----------系统管理---------- */
        isSystemSettings() {
            return this.hasSpecialRole("SystemManagement.SystemSettings")
        },
        isSystemSettingsUpdate() {
            return this.hasSpecialRole("SystemManagement.SystemSettings.Update")
        },
        isLogManage() {
            return this.hasSpecialRole("SystemManagement.Log")
        },
        isLogAuditLog() {
            return this.hasSpecialRole("SystemManagement.Log.AuditLog")
        },
        isLogSecurityLog() {
            return this.hasSpecialRole("SystemManagement.Log.SecurityLog")
        },
        /* ----------资产管理---------- */
        isStockManage() {
            return this.hasSpecialRole("AssetManagement.Stock")
        },
        isStockAssetStock() {
            return this.hasSpecialRole("AssetManagement.Stock.AssetStock")
        },
        isStockWarehouseStock() {
            return this.hasSpecialRole("AssetManagement.Stock.WarehouseStock")
        },
        isStockAssetUserStock() {
            return this.hasSpecialRole("AssetManagement.Stock.AssetUserStock")
        },
        isStockTemporaryStock() {
            return this.hasSpecialRole("AssetManagement.Stock.TemporaryStock")
        },
        isStockDamagedStock() {
            return this.hasSpecialRole("AssetManagement.Stock.DamagedStock")
        },
        isStockInbound() {
            return this.hasSpecialRole("AssetManagement.Stock.Inbound")
        },
        isStockOutbound() {
            return this.hasSpecialRole("AssetManagement.Stock.Outbound")
        },
        isStockExchange() {
            return this.hasSpecialRole("AssetManagement.Stock.Exchange")
        },
        isStockTransfer() {
            return this.hasSpecialRole("AssetManagement.Stock.Transfer")
        },
        isStockDamage() {
            return this.hasSpecialRole("AssetManagement.Stock.Damage")
        },
        isStockChangeLogs() {
            return this.hasSpecialRole("AssetManagement.Stock.ChangeLogs")
        },
        isWarehouseStockChangeLogs() {
            return this.hasSpecialRole("AssetManagement.Stock.WarehouseStockChangeLogs")
        },
        /* ----------基础数据管理---------- */
        isBaseDataManage() {
            return this.hasSpecialRole("BaseDataManagement.BaseData")
        },
        isBaseDataCompanyManage() {
            return this.hasSpecialRole("BaseDataManagement.BaseData.CompanyManage")
        },
        isBaseDataAssetCategoryManage() {
            return this.hasSpecialRole("BaseDataManagement.BaseData.AssetCategoryManage")
        },
        isBaseDataAssetManage() {
            return this.hasSpecialRole("BaseDataManagement.BaseData.AssetManage")
        },
        isBaseDataAssetUserCategoryManage() {
            return this.hasSpecialRole("BaseDataManagement.BaseData.AssetUserCategoryManage")
        },
        isBaseDataAssetUserManage() {
            return this.hasSpecialRole("BaseDataManagement.BaseData.AssetUserManage")
        },
        isBaseDataWarehouseManage() {
            return this.hasSpecialRole("BaseDataManagement.BaseData.WarehouseManage")
        },
    },
    methods: {
        // 传递权限名称，返回是否存在
        isHasPermission(roleName) {
            let index = this.$store.getters.roles.findIndex(role => {
                role = role.replace(".", "")
                roleName = roleName.replace(".", "")
                return role == roleName
            })
            return index != -1
        },
        // 判断是否有指定权限
        hasSpecialRole(role) {
            return this.$store.getters.roles.indexOf(role) != -1
        }
    }
  }
  
  
  
