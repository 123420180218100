<style lang="scss" scoped>
.page-instockbill-list {
    .asset-image {
        width: 26px;
        height: 26px;
        vertical-align: middle;
        .error-image {
            font-size: 26px;
            color: #cacaca;
        }
    }
}
</style>

<template>
    <div class="page-instockbill-list">
        <div class="fr">
            <!--<el-select class="c-el-input mrgr5 mrgb5" placeholder="请输入资产查询" size="medium" clearable remote
                filterable v-model="listQuery.assetId" :remote-method="remoteMethod" :reserve-keyword="true">
                <el-option-group>
                    <div style="padding: 5px 20px;">
                        <div class="col9">图片/资产名称 (条形码)</div>
                    </div>
                    <el-option v-for="(o, i) in assets" :key="i" :value="o.id" :label="o.name">
                        <div class="col9 assets">
                            <span class="mrgr5">
                                <el-image class="asset-image" :src="uploadBaseUrl + o.imageUrl">
                                    <div slot="error">
                                        <i class="el-icon-picture-outline error-image"></i>
                                    </div>
                                </el-image>
                            </span>
                            <span>
                                {{ o.name }} ({{ o.barCode }})
                            </span>
                        </div>
                    </el-option>
                </el-option-group>
            </el-select>-->
            <el-input class="c-el-input mrgr5 mrgb5" placeholder="请输入关键字查询" clearable v-model="listQuery.keywords"
                size="medium"></el-input>
            <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
        </div>
        <div class="clearb"></div>
        <el-table :data="tableData" border size="medium">
            <el-table-column label="图片【分类-条形码】资产名称" min-width="280">
                <template slot-scope="scope" v-if="scope.row.asset">
                    <el-image class="asset-image" v-if="scope.row.asset.imageUrl"
                        :src="uploadBaseUrl + scope.row.asset.imageUrl" 
                        :preview-src-list="[uploadBaseUrl + scope.row.asset.imageUrl]">
                        <div slot="error">
                            <i class="el-icon-picture-outline error-image"></i>
                        </div>
                    </el-image>
                    <span>{{ mxTranceAssetData(scope.row.asset) }}</span>
                    <span :class="mxTranceAssetData(scope.row.asset) ? '' : 'mrgl10'">{{ scope.row.asset.name }}</span>
                </template>
            </el-table-column>
            <el-table-column label="库存数量" prop="stock"></el-table-column>
            <el-table-column label="所属事业部" prop="company.name" min-width="95"></el-table-column>
            <el-table-column label="所属仓库">
                <template slot-scope="scope">
                    <span v-if="scope.row.ownerType === valOwnerWarehouse()">{{ tranceOwnerName(scope.row) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="creationTime" :formatter="tableDateFormat" min-width="100"></el-table-column>
        </el-table>
        <div class="pagination-container" v-if="isPagination">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
                :current-page="listQuery.page" :page-sizes="appPageSizes" :page-size="listQuery.size" 
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import enumConfigs from "@/mixins/enumConfigs"
import * as funInfomation from "@/api/information"
import * as funStock from "@/api/stock"
import { getStockCompany, getStockWarehourse } from "@/utils/storages"
import moment from "moment"
export default {
    name: "pageInStockBill",
    mixins: [enumConfigs],
    data() {
        let that = this
        return {
            tableData: [],
            total: null,
            listQuery: {
                ownerType: null,
                ownerId: "",
                companyId: "",
                categoryId: null,
                assetId: "",
                keywords: null,
                page: 1,
                size: window.$common.appPageSize
            },
            isPagination: false,
            allWarehouse: [],
        }
    },
    watch: {
        "$store.getters.enumConfigs": {
            handler() {
                this.initData()
            },
            deep: true,
            immediate: true
        }
    },
    async mounted() {
        await this.open()
    },
    methods: {
        async open() {
            try {
                // 默认仓库
                let companyItem = getStockCompany()
                let warehourseItem = getStockWarehourse()
                this.listQuery.companyId = companyItem && companyItem.id || ""
                this.listQuery.warehouseId = warehourseItem && warehourseItem.id || ""
            } catch(ex) {
                console.log("catch")
            }
            this.listQuery.page = 1
            this.initData()
        },
        initData() {
            this.listQuery.ownerType = this.valOwnerWarehouse()
            if (this.listQuery.ownerType !== null) {
                this.getAllWarehouse()
                this.getDataList()
            }
        },
        handleSizeChange(val) {
            window.$common.fullLoading()
            this.listQuery.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            window.$common.fullLoading()
            this.listQuery.page = val
            this.getDataList()
        },
        async getAllWarehouse() {
            await funInfomation.searchWarehouse({ companyId: this.listQuery.companyId }).then(res => {
                this.allWarehouse = res.items
            })
        },
        async getDataList() {
            window.$common.fullLoading()
            await funStock.getStockList(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items
                } else {
                    this.tableData = []
                }
            })
        },
        // 所属仓库
        tranceOwnerName(row) {
            let temp = {}
            if (row.ownerType === this.valOwnerWarehouse()) {
                temp = this.allWarehouse.find(x => {
                    return row.ownerId === x.id
                })
            }
            return temp && temp.name || ""
        },
        _search() { 
            this.listQuery.page = 1
            this.getDataList()
        },
        commonSuccess(val) {
            let tips = val || "操作成功!"
            this.alert(tips).then(() => {
                window.$common.closeFullLoading()
                this.getDataList()
            })
        }
    }
}
</script>